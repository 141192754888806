// Constants

import {
    MARKETPLACE_MAIN_PATH,
    MARKETPLACE_DESCRIPTION_PATH,
    MARKETPLACE_DESCRIPTIONS_PATH,
    MARKETPLACE_IMAGE_PATH,
    MARKETPLACE_PACKAGES_LIST_PATH,
    MARKETPLACE_PACKAGES_UPDATES_PATH,
    MARKETPLACE_INSTALLED_PATH,
    MARKETPLACE_PACKAGE_DETAILS_PATH,
    MARKETPLACE_CHANGELOG_PATH,
    MARKETPLACE_DESC_STATE_DRAFT,
    MARKETPLACE_DESC_STATE_PUBLISHED,
    MARKETPLACE_INSTALL_PACKAGE_PATH,
    MARKETPLACE_UNINSTALL_PACKAGE_PATH,
    MARKETPLACE_UPDATE_PACKAGE_PATH,
    MARKETPLACE_UPDATE_ALL_PACKAGE_PATH,
    MARKETPLACE_QUEUE_JOB_RESULT_PATH,
    BACKSTAGE_PACKAGE_DETAILS_PATH,
    MARKETPLACE_PUBLIC_LIST_PATH
} from 'libs/utils/constants';

// Tools

import { localeCompareByKey } from 'libs/utils/collections';
import BaseService from './base-service';

/**
 * Provides a mapping between a description of a package and published packages.
 *
 * The published process of a package is kept mainly untouched, but we now give
 * the possibility to add a more business oriented description of a package.
 *
 * @example
 *
 * import MarketplaceService from 'libs/services/marketplace';
 * ...
 * const mkt = new MarketplaceService();
 */
export default class MarketplaceService extends BaseService {
    /**
     * Gets the list of latest packages that can be installed.
     * Returns only the description for packages that satisfies the following
     * conditions:
     *   - There is a package with a minor/patch version same or above the package
     *     version specified in the document.
     *   - There is no other package with a description with a higher package
     *     version in the published state.
     *   - The description state is published.
     *   - The description private field is not set to true or the private field is
     *     set to true
     *
     * @param {String} eventId the event ID.
     * @param {String} [path] the path where to get the description details
     *
     * @return {Promise} Promise object that represents a list of available
     * descriptions.
     */
    async getMarketplaceDescriptions(eventId, path = MARKETPLACE_MAIN_PATH) {
        const url = path.replace('{{eventId}}', eventId);
        const { data } = await this.getCached(url);

        for (const desc of data) {
            desc.categories = this.transformCategories(desc.categories);
        }

        return data;
    }

    /**
     * Returns a decorated representation of packages descriptions with their
     * respective install status.
     *
     * @param {String} eventId the event ID.
     *
     * @return {Promise} Promise object that represents a list of available
     * descriptions and their status on the specified event.
     */
    async getEventDescriptions(eventId) {
        const [installed, updatable, descriptions] = await Promise.all([
            this.getInstalledPackages(eventId),
            this.getAvailableUpdates(eventId),
            this.getMarketplaceDescriptions(eventId)
        ]);

        return descriptions
            .map(desc => {
                const pkgName = desc.name;
                const update = updatable.find(p => p.name === pkgName);
                const latest = (update || {}).update;

                if (update) {
                    desc.is_critical = update.is_critical;
                    desc.patch = update.patch;
                }

                return this.enrichPackageData(desc, installed[pkgName], latest);
            })
            .sort((a, b) => localeCompareByKey('title', a, b));
    }

    /**
     * Returns a decorated representation of library packages with their
     * respective updatable status.
     *
     * @param {String} eventId the event ID.
     * @param {String[]} [exclude=[]] a list of packages name to hide.
     *
     * @return {Promise<Array>} Promise object that represents a list installed library packages.
     */
    async getEventLibraries(eventId, exclude = []) {
        const [installed, updatable] = await Promise.all([
            this.getInstalledPackages(eventId),
            this.getAvailableUpdates(eventId)
        ]);

        return Object.keys(installed)
            .filter(libName => !exclude.includes(libName))
            .map(libName => {

                const lib = installed[libName];
                const pkg = updatable.find(p => p.name === libName) || {};
                const latest = pkg.update;

                pkg.name = libName;

                return this.enrichPackageData(pkg, lib, latest);
            })
            .sort((a, b) => localeCompareByKey('name', a, b));
    }

    /**
     * Given an event ID this method returns a list of installed packages.
     *
     * @param {String} eventId the event ID.
     * @param {String} [path] the path where to get the installed packages
     *
     * @return {Promise} Promise object that represents a list of instsalled packages
     */
    async getInstalledPackages(eventId, path = MARKETPLACE_INSTALLED_PATH) {
        const url = path.replace('{{eventId}}', eventId);
        const { data } = await this.getCached(url);

        return data;
    }

    /**
     * Given an event ID this method returns all available updates.
     *
     * @param {String} eventId the event ID.
     *
     * @return {Promise} Promise object that represents the updates available.
     */
    async getAvailableUpdates(eventId) {
        const url = MARKETPLACE_PACKAGES_UPDATES_PATH.replace('{{eventId}}', eventId);
        const { data } = await this.getCached(url);

        return data;
    }

    /**
     * Given a package name and an event ID this method returns the package details
     * as provided by the APIs.
     *
     * @private
     *
     * @param {String} pkgName the name of the package
     * @param {String} eventId the event ID.
     *
     * @return {Promise} Promise object that represents the package details.
     */
    async getDescriptionDetails(pkgName, eventId) {
        const url = MARKETPLACE_PACKAGE_DETAILS_PATH
            .replace('{{pkgName}}', pkgName)
            .replace('{{eventId}}', eventId);
        const { data } = await this.get(url);

        data.categories = this.transformCategories(data.categories);

        return data;
    }

    /**
     * Given a package name and an event ID this method returns the package details
     * enriched with install status data.
     *
     * @param {String} pkgName the name of the package
     * @param {String} eventId the event ID.
     *
     * @return {Promise} Promise object that represents the package details.
     */
    async getPackageDetails(pkgName, eventId) {
        const installed = await this.getInstalledPackages(eventId);
        const details = await this.getDescriptionDetails(pkgName, eventId);

        return this.enrichPackageData(details, installed[pkgName], details.package.version);
    }

    /**
     * Calculates some common values in order to have consistent data model across
     * the system.
     *
     * @private
     *
     * @param {Object} description the the package description
     * @param {String} currentInstalled current installed package version
     * @param {String} latest latest available package version
     *
     * @returns the enriched description with additional fields
     */
    enrichPackageData(description, currentInstalled, latest) {
        description.installed = !!currentInstalled;
        description.installedVersion = currentInstalled;
        description.update = description.installed && latest !== currentInstalled ? latest : false;
        description.latest = latest;

        return description;
    }

    /**
     * Gets a list of package versions belonging to this organization.
     *
     * @param {String} orgId the name of the organization.
     *
     * @return {Promise} Promise object that represents a list of packages
     * belonging to this organization.
     */
    async getOrgPackageVersions(orgId) {
        const { data } = await this.get(MARKETPLACE_PACKAGES_LIST_PATH.replace('{{orgId}}', orgId));

        // remove repo version
        delete data.repo;

        return data;
    }

    /**
     * Given a pacakge name this method returns its changelog.
     *
     * @param {String} eventId the event ID.
     * @param {String} pkgName the name of the package
     *
     * @return {Promise} Promise object that represents the package changelog.
     */
    async getPackageChangelog(eventId, pkgName) {
        const url = MARKETPLACE_CHANGELOG_PATH
            .replace('{{eventId}}', eventId)
            .replace('{{pkgName}}', pkgName);
        const { data } = await this.get(url);

        return data;
    }

    /**
     * Given an organization name, a package name and a version this method gets
     * a specific package description.
     *
     * @param {String} orgId the name of the organziation.
     * @param {String} pkgName the name of the package.
     * @param {String} version the version of the package.
     *
     * @return {Promise} Promise object that represents the package description.
     */
    async getPackageDescription(orgId, pkgName, version) {
        const url = MARKETPLACE_DESCRIPTION_PATH
            .replace('{{orgId}}', orgId)
            .replace('{{pkgName}}', pkgName)
            .replace('{{version}}', version);

        const { data } = await this.get(url);

        return data;
    }

    /**
     * Given an organization name, a package name, a version and related data this
     * method saves a specific package description.
     *
     * @param {String} orgId the name of the organziation.
     * @param {String} packageName the name of the package.
     * @param {String} version the version of the package.
     *
     * @param {Object} [putData] data to be saved
     * @param {'draft'|'published'} [putData.state] description state
     * @param {boolean} [putData.private] is this package only for this organization
     * @param {String} [putData.title] package title
     * @param {String} [putData.tagline] short description
     * @param {String} [putData.description] html description
     * @param {String} [putData.description_url] link for advanced support
     * @param {String} [putData.changelog] description of version change
     * @param {Array<String>} [putData.categories] list of categories for this package
     *
     * @return {Promise} Promise object that represents the package description.
     */
    async savePackageDescription(orgId, packageName, version, putData) {
        const url = MARKETPLACE_DESCRIPTION_PATH
            .replace('{{orgId}}', orgId)
            .replace('{{pkgName}}', packageName)
            .replace('{{version}}', version);
        const { data } = await this.put(url, putData);

        if (data.error) {
            throw new Error(data.error);
        }

        return data.status === 'ok';
    }

    /**
     * Given an organization name, a description id and an image id this method
     * saves the package image data.
     *
     * @param {String} orgId the name of the organziation.
     * @param {String} packageName the name of the package.
     * @param {String} version the version of the package.
     * @param {String} imageId the ID of the image
     * @param {Object} image image data
     *
     * @return {Promise} Promise object that represents the image.
     */
    async savePackageImage(orgId, packageName, version, imageId, image) {
        const { data: { token } } = await this.get('/api/v1/app-brandings/csrf-token');

        const url = MARKETPLACE_IMAGE_PATH
            .replace('{{orgId}}', orgId)
            .replace('{{pkgName}}', packageName)
            .replace('{{version}}', version)
            .replace('{{imageId}}', imageId);

        const formData = new FormData();

        formData.append('_csrf', token);
        formData.append('file', image);

        const { data } = await this.post(url, formData);

        if (data.error) {
            throw new Error(data.error);
        }

        return data.status === 'ok';
    }

    /**
     * Given an organization name, a description id and an image id this method
     * deletes the package image.
     *
     * @param {String} orgId the name of the organziation.
     * @param {String} packageName the name of the package.
     * @param {String} version the version of the package.
     * @param {String} imageId the ID of the image to delete
     *
     * @return {Promise} Promise object that represents the image.
     */
    async deletePackageImage(orgId, packageName, version, imageId) {
        const url = MARKETPLACE_IMAGE_PATH
            .replace('{{orgId}}', orgId)
            .replace('{{pkgName}}', packageName)
            .replace('{{version}}', version)
            .replace('{{imageId}}', imageId);
        const { data } = await this.delete(url);

        if (data.error) {
            throw new Error(data.error);
        }

        return data.status === 'ok';
    }

    /**
     * Get packages with description owned by organization
     *
     * @param {string} orgId organization ID
     * @returns {Promise<array>} Array of objects representing packages
     */
    async getPackageDescriptions(orgId) {
        const url = MARKETPLACE_DESCRIPTIONS_PATH.replace('{{orgId}}', orgId);
        const { data } = await this.get(url);

        return data;
    }

    /**
     * Checks if the state is 'draft'
     *
     * @param {string} state
     * @returns {boolean}
     */
    isDescStateDraft(state) {
        return state === MARKETPLACE_DESC_STATE_DRAFT;
    }

    /**
     * Checks if the state is 'published'
     *
     * @param {string} state
     * @returns {boolean}
     */
    isDescStatePublished(state) {
        return state === MARKETPLACE_DESC_STATE_PUBLISHED;
    }

    /**
     * Installs specified package
     *
     * @param {String} eventId the event on which install the package
     * @param {String} pkgName the package name
     * @param {String} [version=null] the package version
     * @param {Boolean} [dryrun=false] whether to install the package or run an install check
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async installPackage(eventId, pkgName, version = null, dryrun = false) {
        return this._executePackageAction({ action: 'install', eventId, dryrun, pkgName, version });
    }

    /**
     * Uninstalls specified package
     *
     * @param {String} eventId the event on which uninstall the package
     * @param {String} pkgName the package name
     * @param {Boolean} [dryrun=false] whether to uninstall the package or run an install check
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async uninstallPackage(eventId, pkgName, dryrun = false) {
        return this._executePackageAction({ action: 'uninstall', eventId, dryrun, pkgName });
    }


    /**
     * Updates specified package
     *
     * @param {String} eventId the event on which update the package
     * @param {String} pkgName the package name
     * @param {String} [version=null] the package version
     * @param {Boolean} [dryrun=false] whether to update the package or run an update check
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async updatePackage(eventId, pkgName, version = null, dryrun = false) {
        return this._executePackageAction({ action: 'update', eventId, dryrun, pkgName, version });
    }

    /**
     * Updates all packages in a workspace.
     *
     * @param {String} eventId the event on which update the packages
     * @param {'marketplace'|'libs'|'all'} updateType the update type to perform on the event
     * @param {Boolean} [dryrun=false] whether to update packages or run an update check
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async updateAllPackages(eventId, updateType, dryrun = false) {
        return this._executePackageAction({ action: 'update_all', eventId, dryrun, updateType });
    }

    /**
     * Executes specified action over the given package.
     *
     * @private
     *
     * @param {Object} options the object that contains the options for package action.
     * @param {'install'|'update'|'update_all'|'uninstall'} options.action the action to perform on the package.
     * @param {String} options.eventId the event on which apply the action.
     * @param {Boolean} [options.dryrun=false] whether to perform the action or simulate it.
     * @param {String} [options.pkgName] the package name.
     * @param {String} [options.version] the package version.
     * @param {'marketplace'|'libs'|'all'} [options.updateType] in case of update all, the update type.
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async _executePackageAction(options) {
        const { action, eventId, dryrun = false, pkgName, version, updateType } = options;

        const payload = { version, dryrun };
        const paths = {
            'install': MARKETPLACE_INSTALL_PACKAGE_PATH,
            'uninstall': MARKETPLACE_UNINSTALL_PACKAGE_PATH,
            'update': MARKETPLACE_UPDATE_PACKAGE_PATH,
            'update_all': MARKETPLACE_UPDATE_ALL_PACKAGE_PATH
        };
        const url = paths[action]
            .replace('{{eventId}}', eventId)
            .replace('{{pkgName}}', pkgName);

        if (action === 'update_all') {
            payload.type = updateType;
        }

        try {
            const { data } = await this.post(url, payload);

            return data;
        } catch ({ response: { data, status } }) {
            const error = { error: 'errors.server.generic', message: data, status };

            if (status !== 200) {
                error.error = `errors.server.${status}`;
            }

            console.warn('[MarketplaceService] An error occurred while performing `%s` the package:', action, error, status);
            return error;
        }
    }

    /**
     * Transforms package category names
     * Categories are saved in DB with spaces ie. `content and logistics`
     * which doesn't work well as a key for translations
     *
     * @param {Array<String>} categories array of category names
     * @returns {Array<String>} array with category names suitable as translation keys
     */
    transformCategories(categories) {
        return categories.map(cat => cat.replace(/\s+/g, '_'));
    }

    /**
     * Given an event ID, this method gets the job result URL
     *
     * @param {String} eventId the event ID
     *
     * @returns {String} the job queue result URL
     */
    getQueueJobResultUrl(eventId) {
        return MARKETPLACE_QUEUE_JOB_RESULT_PATH.replace('{{eventId}}', eventId);
    }

    /**
     * Given an event ID, and a package name this method returns the package details url.
     *
     * @param {String} eventId the event ID
     * @param {String} pkgName the package name
     *
     * @returns {String} the package details path
     */
    getDetailsPageUrl(eventId, pkgName) {
        return BACKSTAGE_PACKAGE_DETAILS_PATH
            .replace('{{eventId}}', eventId)
            .replace('{{pkgName}}', pkgName);
    }

    /**
     * Checks if the given package is installed in the workspace
     *
     * @param {String} eventId the event ID
     * @param {String} pkgName the package name
     *
     * @returns {Promise<boolean>} whether the package is installed
     */
    async isPackageInstalled(eventId, pkgName) {
        const packages = await this.getInstalledPackages(eventId);
        return (packages || {}).hasOwnProperty(pkgName);
    }

    /**
     * Get list of publicly available packages
     *
     * @returns {Promise<array>} Array of objects representing packages
     */
    async getPublicPackages() {
        const { data } = await this.get(MARKETPLACE_PUBLIC_LIST_PATH);
        return data;
    }
}
