// Utils
import { cloneDeep, isNil } from 'lodash';
import { kindOptionsFromSelect } from 'libs/utils/collections';
import { getBackstageURL } from 'libs/utils/url';

// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH, ACT_DOC_ENDPOINT, EVENT_ASSETS_UPLOAD_ENDPOINT } from 'libs/utils/constants';

/**
 * Registration page config update API endpoint. Interpolation: `{{eventId}}`.
 * @constant {String} REGISTRATION_CONFIG_ENDPOINT
 */
const REGISTRATION_CONFIG_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/registration`;

/**
 * Registration page URL existance check API endpoint. Interpolations: `{{eventId}}`, `{{path}}`.
 * @constant {String} REGISTRATION_URL_EXISTS_ENDPOINT
 */
const REGISTRATION_URL_EXISTS_ENDPOINT = `${REGISTRATION_CONFIG_ENDPOINT}/{{path}}`;

/**
 * Registration asset endpoint. Interpolations: `{{eventId}}`, `{{assetType}}`.
 * @constant {String} REGISTRATION_ASSET_ENDPOINT
 */
const REGISTRATION_ASSET_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/registration/assets/{{assetType}}`;

/**
 * Legal documents by event id endpoint. Interpolations: `{{eventId}}`
 * @constant {String} LEGAL_DOCUMENTS_BY_EID_ENDPOINT
 */
const LEGAL_DOCUMENTS_BY_EID_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/org-legal-documents`;

/**
 * Registration asset endpoint. Interpolations: `{{eventId}}`, `{{assetType}}`.
 * @constant {String} SET_PAX_REQUEST_STATUS_ENDPOINT
 */
const SET_PAX_REQUEST_STATUS_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/public-login/{{action}}-requests`;

/**
 * Participant creation endpoint
 * @constant {String} CREATE_PAX_ENDPOINT
 */
const CREATE_PAX_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/data/person`;

/**
 * Participant details endpoint
 * @constant {String} PAX_INFO_CONTENT_HUB_ENDPOINT
 */
const PAX_INFO_CONTENT_HUB_ENDPOINT = `${API_BASE_PATH}/content-hubs/{{eventId}}/users/{{id}}`;

/**
 * @const {string} PAX_INFO_ENDPOINT
 * @private
 */
const PAX_INFO_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/paxinfo/{{pid}}`;

/**
 * @const {string} PAX_ACTCODE_ENDPOINT
 * @private
 */
const PAX_ACTCODE_ENDPOINT = `${PAX_INFO_ENDPOINT}/actcode`;

/**
 * Participant editing endpoint
 * @constant {String} UPDATE_PAX_ENDPOINT
 */
const UPDATE_PAX_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/doc/person/{{id}}`;

/**
 * @const {string} USERS_LIST_ENDPOINT the endpoint to get all users. Interpolations: {{eventId}}
 * @private
 */
const USERS_LIST_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/users`;

/**
 * @const WEBAPP_ACCESS_ENDPOINT the endpoint to get link to access webapp as a pax
 * @private
 */
const WEBAPP_ACCESS_ENDPOINT_FOR_PAX = `${API_BASE_PATH}/eid/{{eventId}}/backstage/webapp-login-url`;

/**
 * @const WEBAPP_ACCESS_ENDPOINT_FOR_BSTG_USER the endpoint to get link to access webapp as a backstage user
 * @private
 */
const WEBAPP_ACCESS_ENDPOINT_FOR_BSTG_USER = `${API_BASE_PATH}/event/{{eventId}}/backstage/webapp-login-url`;

/**
 * @const {string} PAX_BADGE_PRINT_PATH the endpoint to get the badge print path. Interpolations: {{eventId}}, {{userFpExtId}}
 * @private
 */
const PAX_BADGE_PRINT_PATH = '/event/{{eventId}}/print?filter=targets&filter_prop=fp_ext_id&filter_val={{userFpExtId}}&usecase=badge&paper=fit&fullscreen=controller';

/**
 * @const {number} MAX_PAX_PER_PAGE the maximum number of items to load per page
 * @private
 */
const MAX_PAX_PER_PAGE = 25;

/**
 * Provides utils for getting participants data.
 *
 * @example
 * import PaxService from 'libs/services';
 * ...
 * const apiDoc = new PaxService();
 */
export default class PaxService extends BaseService {

    get MAX_PAX_PER_PAGE() {
        return MAX_PAX_PER_PAGE;
    }

    /**
     * Given a set of pax IDs and an event id this method returns the activation document.
     *
     * @param {String} eventId the ID of the event
     * @param {String[]} paxIds an array of pax IDs
     *
     * @return {Promise} Promise object that represents the activation document.
     */
    async getActDocs(eventId, paxIds) {
        const url = ACT_DOC_ENDPOINT.replace('{{eventId}}', eventId);
        const { data: { actdocs } } = await this.post(url, { pax_ids: paxIds });

        return actdocs;
    }

    /**
     * Checks whether the given registration URL exists in our systems or not.
     *
     * @param {string} eventId the workspace ID
     * @param {string} path the path name to check
     *
     * @returns {Promise<boolean>} true if the URL exists, false otherwise
     */
    async registrationUrlExists(eventId, path) {
        const url = this.buildUrl(REGISTRATION_URL_EXISTS_ENDPOINT, { eventId, path });
        const { status } = await this.head(url);
        return status === 200;
    }

    /**
     * Creates and enables the registration URL at the specified path
     *
     * @param {string} eventId the workspace ID
     * @param {{ path_name: string, access_type: 'public'|'private'|'hybrid' }} options the path of the URL to create
     *
     * @returns {Promise} the server response.
     */
    enableRegistration(eventId, { path_name, access_type }) {
        return this.saveRegistrationConfig(eventId, {
            event_details: { path_name },
            settings: {
                enabled: true,
                access_type,
                allow_profile_updates: access_type !== 'public'
            }
        });
    }

    /**
     * Saves the registration page configuration
     *
     * @param {string} eventId the workspace ID
     * @param {object} config the registration configuration object
     *
     * @returns {Promise} the server response
     */
    async saveRegistrationConfig(eventId, config) {
        config = this.sanitizeRegistrationPayload(cloneDeep(config));
        console.debug('[PaxService] Saving registration config', config);

        const url = this.buildUrl(REGISTRATION_CONFIG_ENDPOINT, { eventId });
        const { data } = await this.put(url, config);
        return data;
    }

    /**
     * Cleans the configuration object from unecessary or ignored data
     *
     * @param {object} config the registration configuration
     *
     * @return {object} a version of the cleansed configuration
     */
    sanitizeRegistrationPayload(config) {
        if (!config.forms) {
            return config;
        }

        for (const [name, form] of Object.entries(config.forms)) {
            if (!form.fields.length) {
                continue;
            }

            config.forms[name].fields = form.fields.map(f => this.sanitizeRegistrationField(f));
        }

        return config;
    }

    /**
     * Sanitize a given field and prepares it for the BE request call
     *
     * @param {DynamicField} regField the original payload field
     *
     * @returns {object} sanitized version of the same field
     *
     * @private
     */
    sanitizeRegistrationField(regField) {
        const field = {
            name: regField.name,
            required: regField.required || false,
            not_updatable: regField.not_updatable || false,
            type: regField.type,
            text: regField.text,
            id: regField.id,
            kind_options: regField.kind_options
        };

        if (regField.kind === 'timestamp') {
            field.kind_options = regField.kind_options;
        }

        if (regField.values?.length) {
            field.kind_options = {
                values: kindOptionsFromSelect(regField.values)
            };
        }

        if (regField.fp_ext_ids?.length) {
            field.fp_ext_ids = regField.fp_ext_ids;
        }

        if (!isNil(regField.content)) {
            field.content = regField.content;
        }

        return field;
    }

    /**
     * Loads the registration configuration for the specified event
     *
     * @param {string} eventId the workspace ID
     * @param {number} [cacheRetention] if provided, the call result will be cached for this amounts of milliseconds
     *
     * @returns {Promise} the configuration object
     */
    async getRegistrationConfig(eventId, cacheRetention) {
        const url = this.buildUrl(REGISTRATION_CONFIG_ENDPOINT, { eventId });
        let getter;
        if (Number.isFinite(cacheRetention)) {
            console.debug('[PaxService] Loading registration configuration for event (cached %ds)', Math.round(cacheRetention / 1000), eventId);
            getter = this.getCached.bind(this, url, {}, cacheRetention);
        } else {
            console.debug('[PaxService] Loading registration configuration for event (uncached)', eventId);
            getter = this.get.bind(this, url);
        }

        const { data } = await getter();
        return data;
    }

    /**
     * Uploads a single asset
     *
     * @param {string} eventId the ID of the content hub
     * @param {File} file the file to upload
     * @param {string} assetType the name of the asset
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async uploadAsset(eventId, file, assetType) {
        const url = this.buildUrl(REGISTRATION_ASSET_ENDPOINT, { eventId, assetType });
        const bodyFormData = new FormData();
        const token = await this.getCsrfToken();

        bodyFormData.set('file', file);
        bodyFormData.set('_csrf', token);

        return this.put(url, bodyFormData, { withCredentials: true });
    }

    /**
     * Deletes an asset
     *
     * @param {string} eventId
     * @param {string} assetType
     *
     * @returns {Promise<import('axios').AxiosResponse>}
     */
    async deleteAsset(eventId, assetType) {
        const url = this.buildUrl(REGISTRATION_ASSET_ENDPOINT, { eventId, assetType });

        return this.delete(url);
    }

    /**
     * Returns the path of the requested asset
     *
     * @param {string} eventId the ID of the event
     * @param {string} assetType the name of the asset
     *
     * @returns {string} the path of the desired asset
     */
    getAssetPath(eventId, assetType) {
        return this.buildUrl(REGISTRATION_ASSET_ENDPOINT, { eventId, assetType });
    }

    /**
     * @param {string} eventId
     * @param {string[]} paxRequestIds
     * @param {'approve' | 'deny'} action
     */
    async setPaxRequestStatus(eventId, paxRequestIds, action) {
        const url = this.buildUrl(SET_PAX_REQUEST_STATUS_ENDPOINT, { eventId, action });
        return this.post(url, { paxRequestIds });
    }

    /**
     * Creates a participant on the given event
     *
     * @param {string} eventId the ID of the event
     * @param {Pax} participant the participant object
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async createPax(eventId, participant) {
        const url = this.buildUrl(CREATE_PAX_ENDPOINT, { eventId });
        return this.post(url, { doc: { ...participant } });
    }

    /**
     * Updates a participant on the given event
     *
     * @param {string} eventId the ID of the event
     * @param {Pax} participant the participant object
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async updatePax(eventId, participant) {
        const url = this.buildUrl(UPDATE_PAX_ENDPOINT, { eventId, id: participant._id });
        return this.put(url, { doc: { ...participant } });
    }

    /**
     * Updates a participant on the given event marking it cancelled
     *
     * @param {string} eventId the ID of the event
     * @param {Pax} participant the participant object
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async cancelPax(eventId, participant) {
        participant.fp_status = 'cancelled';
        const url = this.buildUrl(UPDATE_PAX_ENDPOINT, { eventId, id: participant._id });
        return this.put(url, { doc: { ...participant } });
    }

    /**
     * Updates a participant on the given event marking it cancelled
     *
     * @param {string} eventId the ID of the event
     * @param {Pax[]} participants the participant object
     *
     * @returns {Promise<import('axios').AxiosResponse[]>} the server response
     */
    async bulkCancelPax(eventId, participants) {
        const responses = [];
        for (const pax of participants) {
            let resp;
            try {
                resp = await this.cancelPax(eventId, pax);
            } catch (error) {
                resp = error.response;
            }

            responses.push(resp);
        }

        return responses;
    }

    /**
     * Loads the given user's details
     *
     * @param {string} eventId the ID of the event
     * @param {string} id the ID of the user to load the details of
     *
     * @returns {Promise<Pax>} the user's details
     */
    async getPax(eventId, id) {
        const url = this.buildUrl(PAX_INFO_CONTENT_HUB_ENDPOINT, { eventId, id });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Loads legal documents for registration
     *
     * @param {string} eventId
     * @param {boolean} [onlyWithRequirements]
     * @returns {Promise<{_id:string,title:string;last_change:number}[]>}
     */
    async getLegalDocumentsByEid(eventId, onlyWithRequirements) {
        let url = this.buildUrl(LEGAL_DOCUMENTS_BY_EID_ENDPOINT, { eventId });
        if (onlyWithRequirements) { url = `${url}?onlyWithRequirements=true`; }
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Gets the list of all event's participants.
     *
     * @param {string} eventId the ID of the event
     * @param {object} params
     * @param {string} [params.search] an optional search query
     * @param {number} [params.limit] an optional limit
     * @param {number} [params.page] an optional page
     * @param {number} [params.sort] sort the result by this key
     * @param {number} [params.reverse] reverse sorting
     *
     * @returns {Promise<object[]>} the list of participants
     */
    async getAll(eventId, params) {
        const url = this.buildUrl(USERS_LIST_ENDPOINT, { eventId });
        const hasSearch = params?.search?.length > 0 || false;
        const limit = hasSearch ? 0 : params.limit || MAX_PAX_PER_PAGE;
        const skip = hasSearch ? 0 : ((params.page || 1) - 1) * limit;
        const { data } = await this.get(url, { params: {
            limit, skip,
            search: params.search,
            sort: params.sort,
            reverse: params.reverse
        } });
        return data;
    }

    /**
     * Retrieves the access webapp link for a given event and participant.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} [pid] - The participant ID.
     * @param {object} [options] - Additional options.
     * @param {string} [options.redirect] - The URL to redirect to after logging
     *
     * @returns {Promise<string>} - A promise that resolves to the URL of the access webapp link.
     */
    async getAccessWebappLink(eventId, pid, { redirect } = {}) {
        const url = this.buildUrl(WEBAPP_ACCESS_ENDPOINT_FOR_PAX, { eventId });
        const { data } = await this.get(url, { params: {
            format: 'json', pid, redirect
        } });
        return data.url;
    }

    /**
     * Retrieves the access webapp link for a given event and backstage user.
     *
     * @param {string} eventId - The ID of the event.
     * @param {object} [options] - Additional options.
     * @param {string} [options.redirect] - The URL to redirect to after logging
     * @param {string} [options.userId] - The ID of the backstage user.
     *
     * @returns {Promise<string>} - A promise that resolves to the URL of the access webapp link.
     */
    async getAccessWebappLinkForBackstageUser(eventId, { redirect, userId }) {
        const url = this.buildUrl(WEBAPP_ACCESS_ENDPOINT_FOR_BSTG_USER, { eventId });
        const { data } = await this.post(url, {
            format: 'json', redirect, userId
        }, { withCredentials: true });
        return data.url;
    }

    /**
     * Generates the URL path for badge printing.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} userFpExtId - The external ID of the user.
     * @returns {string} The constructed URL path for badge printing.
     */
    getBadgePrintingPath(eventId, userFpExtId) {
        return this.buildUrl(PAX_BADGE_PRINT_PATH, { eventId, userFpExtId });
    }

    /**
     * Retrieves passenger information along with their timeline for a specific event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} pid - The passenger ID.
     * @returns {Promise<Record<string,unknown>>} A promise that resolves to the passenger information and timeline data.
     */
    async getPaxInfoWithTimeline(eventId, pid) {
        const url = this.buildUrl(PAX_INFO_ENDPOINT, { eventId, pid });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Retrieves the Pax Activity Code for a given event and participant.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} pid - The participant ID.
     * @returns {Promise<string>} - A promise that resolves to the activity code, or an empty string if not found.
     */
    async getPaxActCode(eventId, pid) {
        const url = this.buildUrl(PAX_ACTCODE_ENDPOINT, { eventId, pid });
        const { data } = await this.get(url);
        return data?.code || '';
    }

    /**
     * Uploads an avatar for a person.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} nodeId - The ID of the node.
     * @param {string} pax - The participant document
     * @param {File} file - The file to be uploaded.
     *
     * @returns {Promise<Object>} The response data from the upload.
     */
    async uploadAvatar(eventId, nodeId, pax, file) {
        if (!file || file.size === 0) {
            return;
        }

        const params = {
            fpType: 'person_photo',
            fileName: file.name,
            fpOwner: pax._id,
            docData: {
                _id: `${pax._id}-photo`,
                fp_ext_id: pax.fp_ext_id,
                fp_repl: 'safe',
                fp_tenants: ['global']
            }
        };

        const token = await this.getCsrfTokenViaRouter(nodeId);
        const endpoint = this.buildUrl(EVENT_ASSETS_UPLOAD_ENDPOINT, { eventId });
        const url = getBackstageURL(nodeId, endpoint);

        const bodyFormData = new FormData();

        bodyFormData.set('file', file);
        bodyFormData.set('_csrf', token);
        bodyFormData.set('params', JSON.stringify(params));

        const { data } = await this.post(url, bodyFormData, { withCredentials: true });

        return data;
    }
}
